html {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: #379028;
}
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mobile {
      display: block;
  }
  .desktop {
      display: none;
  }
}

.no-underline {
  text-decoration: none;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9)); /* Gradient overlay */
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 0; /* Adjust this */
  transform: translateY(-50%); /* Adjust this */
  color: white;
  background-color: rgba(0, 126, 27, 0.8); /* green background with 70% opacity */
  font-size: clamp(1rem, 3vw, 2rem);
  padding: 2rem;
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: left;
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
}

.services-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.service-button {
  box-shadow: 0px 2px 10px 2px #c9c9c9;
  margin: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 20rem;
  height: 6rem;
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.service-button:hover {
  background-color: #379028;
  cursor: pointer;
}
.service-button h2 {
  transition: color 0.3s ease;
}
.service-button:hover h2 {
  color: white;
}



/* Auto-generated */
.App {
  text-align: center;
}